import PropTypes from "prop-types"
import React from "react"

export default function ClientOnly({ children }) {
  const [hasMounted, setHasMounted] = React.useState(false)

  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }
  return children
}
ClientOnly.propTypes = {
  children: PropTypes.node,
}
