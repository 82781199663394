import {
  faAdjust,
  faBolt,
  faChartPie,
  faDice,
  faRandom,
  faSortNumericDown,
} from "@fortawesome/free-solid-svg-icons"

import Container from "react-bootstrap/Container"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LocalizedLink from "components/localized-link"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import PropTypes from "prop-types"
import { RaffleTypes } from "components/layout-cekilis"
import React from "react"
import { faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons"
import useTranslations from "components/use-translations"

export default function Header({ type }) {
  const {
    cekilis: {
      hizliCekilis,
      afilliCekilis,
      cark,
      rastgeleSayi,
      zar,
      yaziTura,
      instagramAraci,
      tikTokAraci,
    },
  } = useTranslations()
  return (
    <div className="border-bottom border-silver shadow-sm">
      <Container style={{ maxWidth: 1268 }}>
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="light"
          className="d-flex w-100 px-0 py-0"
          style={{ fontSize: 16, lineHeight: "18px" }}
        >
          <Navbar.Brand className="pr-3">
            <LocalizedLink to="/cekilis/">
              <img
                className="mb-0"
                src="/img/cekilis-logo.png"
                width="150"
                alt="Kodvizit Logo"
              ></img>{" "}
            </LocalizedLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav ">
            <Nav></Nav>
            <Nav className="ml-auto ">
              <Nav.Link
                as={LocalizedLink}
                to="/cekilis/"
                className={`my-auto ml-md-3 border-info ${
                  type === RaffleTypes.Hizli && "border-bottom"
                }`}
              >
                <div
                  className="d-inline-block"
                  style={{ width: "16px", height: "16px" }}
                >
                  <FontAwesomeIcon icon={faBolt} />
                </div>{" "}
                {hizliCekilis}
              </Nav.Link>
              <Nav.Link
                as={LocalizedLink}
                to="/cekilis/afillicekilis/"
                className={`my-auto ml-md-3 border-info ${
                  type === RaffleTypes.Afilli && "border-bottom"
                }`}
              >
                <div
                  className="d-inline-block"
                  style={{ width: "16px", height: "16px" }}
                >
                  <FontAwesomeIcon icon={faRandom} />
                </div>{" "}
                {afilliCekilis}
              </Nav.Link>
              <Nav.Link
                as={LocalizedLink}
                to="/cekilis/cark/"
                className={`my-auto ml-md-3 border-info ${
                  type === RaffleTypes.Cark && "border-bottom"
                }`}
              >
                <div
                  className="d-inline-block"
                  style={{ width: "16px", height: "16px" }}
                >
                  <FontAwesomeIcon icon={faChartPie} />
                </div>{" "}
                {cark}
              </Nav.Link>
              <Nav.Link
                as={LocalizedLink}
                to="/cekilis/rastgele-sayi/"
                className={`my-auto ml-md-3 border-info ${
                  type === RaffleTypes.RastgeleSayi && "border-bottom"
                }`}
              >
                <div
                  className="d-inline-block"
                  style={{ width: "16px", height: "16px" }}
                >
                  <FontAwesomeIcon icon={faSortNumericDown} />
                </div>{" "}
                {rastgeleSayi}
              </Nav.Link>
              <Nav.Link
                as={LocalizedLink}
                to="/cekilis/zar/"
                className={`my-auto ml-md-3 border-info ${
                  type === RaffleTypes.Zar && "border-bottom"
                }`}
              >
                <div
                  className="d-inline-block"
                  style={{ width: "16px", height: "16px" }}
                >
                  <FontAwesomeIcon icon={faDice} />
                </div>{" "}
                {zar}
              </Nav.Link>
              <Nav.Link
                as={LocalizedLink}
                to="/cekilis/yazi-tura/"
                className={`my-auto ml-md-3 border-info ${
                  type === RaffleTypes.YaziTura && "border-bottom"
                }`}
              >
                <div
                  className="d-inline-block"
                  style={{ width: "16px", height: "16px" }}
                >
                  <FontAwesomeIcon icon={faAdjust} />
                </div>{" "}
                {yaziTura}
              </Nav.Link>
              <Nav.Link
                as={LocalizedLink}
                to="/cekilis/instagram-cekilis-araci/"
                className={`my-auto ml-md-3 border-info ${
                  type === RaffleTypes.Instagram && "border-bottom"
                }`}
              >
                <div
                  className="d-inline-block"
                  style={{ width: "16px", height: "16px" }}
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </div>{" "}
                {instagramAraci}
              </Nav.Link>
              <Nav.Link
                as={LocalizedLink}
                to="/cekilis/tiktok-cekilis-araci/"
                className={`my-auto ml-md-3 border-info ${
                  type === RaffleTypes.TikTok && "border-bottom"
                }`}
              >
                <div
                  className="d-inline-block"
                  style={{ width: "16px", height: "16px" }}
                >
                  <FontAwesomeIcon icon={faTiktok} />
                </div>{" "}
                {tikTokAraci}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  )
}

Header.propTypes = {
  type: PropTypes.number.isRequired,
}
