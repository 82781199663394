import CekilisSonuc from "./sonuc"
import ClientOnly from "components/client-only"
import LayoutCekilis from "components/layout-cekilis"
import React from "react"
import { Router } from "@reach/router"

const CekilisSonucBase = () => (
  <ClientOnly>
    <LayoutCekilis>
      <Router basepath="/cekilis/s">
        <CekilisSonuc default path=":id" />
      </Router>
    </LayoutCekilis>
  </ClientOnly>
)

export default CekilisSonucBase
