const API_BASE = "https://www.kodvizit.com/ynt/php"
// "/ynt/php"
const makeRequest = postBody => {
  var formBody = []
  for (var property in postBody) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(postBody[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")

  return fetch(`${API_BASE}/fonksiyon.php`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: formBody,
  })
}

function createBody(cagrilanFonks, veri) {
  return { cagrilanFonks, veri }
}

function convertSpacesSingle(text) {
  return text ? text.trim().replace(/\s\s+/g, " ") : ""
}

/* Sipariş */
export function getSiparis() {
  return makeRequest(createBody("SiparisGetirJson", ""))
}

export function getSiparisById(orderId) {
  return makeRequest(createBody("SiparisGetirJson", orderId))
}

export function getDosyalar(orderId) {
  return makeRequest(createBody("DosyalariGetir", orderId))
}

export function getLisans(order) {
  const orderCopy = {
    siparisno: order["post_id"],
    programkod: order["program_kodu"],
    siparistarih: order["post_date"],
    urun: order["order_items"],
    modul: order["x-moduller"],
    ad:
      convertSpacesSingle(order["_billing_first_name"]) +
      convertSpacesSingle(order["_billing_last_name"].trim()),
    mail: order["_billing_email"],
    lisans: "lisans",
  }
  return makeRequest(createBody("LisansUretJson", JSON.stringify(orderCopy)))
}

export function getFatura(order) {
  const orderCopy = {
    toplam: order["_order_total"],
    vergi: order["_order_tax"],
    sirket: order["_billing_company"],
    adres: order["_billing_address_1"],
    faturaseri: order["x-fatura_seri"],
    faturano: order["x-fatura_no"],
    odemetarih: order["post_date"],
    vergidaire: order["vergi_dairesi"],
    vergino: order["vergi_no"],
    urun: order["order_items"],
    sehir: order["_billing_city"] + "/" + order["_billing_state"],
    ad:
      convertSpacesSingle(order["_billing_first_name"]) +
      " " +
      convertSpacesSingle(order["_billing_last_name"].trim()),
    mail: order["_billing_email"],
  }
  return makeRequest(createBody("FaturaUretJson", JSON.stringify(orderCopy)))
}

export function sendLisansMail(order, imza) {
  const orderCopy = {
    siparisno: order["post_id"],
    odemetarih: order["post_date"],
    programkod: order["program_kodu"],
    odemeyontemi: order["_payment_method_title"],
    ip: order["_customer_ip_address"],
    telefon: order["_billing_phone"],
    adres: order["_billing_address_1"],
    sehir: order["_billing_city"],
    urun: order["order_items"],
    toplam: order["_order_total"],
    mail: order["_billing_email"],
    imza: imza,
    ad:
      convertSpacesSingle(order["_billing_first_name"]) +
      " " +
      convertSpacesSingle(order["_billing_last_name"].trim()),
  }
  return makeRequest(
    createBody("LisansMailGonderJson", JSON.stringify(orderCopy))
  )
}

export function sendFaturaMail(order, imza, dosyaAdi) {
  const orderCopy = {
    siparisno: order["post_id"],
    odemetarih: order["post_date"],
    faturaseri: order["x-fatura_seri"],
    faturano: order["x-fatura_no"],
    mail: order["_billing_email"],
    imza: imza,
    dosyaAdi,
    toplam: order["_order_total"],
    ad:
      convertSpacesSingle(order["_billing_first_name"]) +
      " " +
      convertSpacesSingle(order["_billing_last_name"].trim()),
  }
  return makeRequest(
    createBody("FaturaMailGonderJson", JSON.stringify(orderCopy))
  )
}
export function uploadFaturaFile(file) {
  const formData = new FormData()
  formData.append("earsiv_zip", file)
  formData.append("cagrilanFonks", "FaturaYukle")
  return fetch(`${API_BASE}/fonksiyon.php`, {
    method: "POST",
    body: formData,
  })
}

export function updateOrder(order) {
  const orderCopy = {
    siparisno: order["post_id"],
    ad: convertSpacesSingle(order["_billing_first_name"]),
    soyad: convertSpacesSingle(order["_billing_last_name"]),
    durum: order["post_status"],
    odemetarih: order["post_date"],
    sirket: order["_billing_company"],
    mail: order["_billing_email"],
    telefon: order["_billing_phone"],
    adres: order["_billing_address_1"],
    adres2: order["_billing_address_2"],
    sehir: order["_billing_city"],
    odemeyontemi: order["_payment_method_title"],
    programkod: order["program_kodu"],
    vergidaire: order["vergi_dairesi"],
    vergino: order["vergi_no"],
  }
  return makeRequest(createBody("GuncellemeJson", JSON.stringify(orderCopy)))
}

/* Geribildirim */
export function getGeribildirim() {
  return makeRequest(createBody("GeribildirimGetirJson", ""))
}

export function getGeribildirimById(feedbackId) {
  return makeRequest(createBody("GeribildirimGetirJson", feedbackId))
}

export function getGeribildirimDosyalar(fileName) {
  return makeRequest(createBody("GeribildirimDosyalariGetir", fileName))
}
export function removeGeribildirim(feedbackId) {
  return makeRequest(createBody("GeribildirimSil", feedbackId))
}
export function updateGeribildirim(geribildirim) {
  const body = {
    cagrilanFonks: "GeribildirimGuncelle",
    veri: geribildirim.Id,
    ["e-posta"]: geribildirim.EPosta,
    cevap: geribildirim.Cevap,
  }
  return makeRequest(body)
}

export function sendGeribildirimPassword(geribildirim) {
  const body = {
    cagrilanFonks: "SifreMailGonder",
    sifre: geribildirim.Sifre,
    ["e-posta"]: geribildirim.EPosta,
  }
  return makeRequest(body)
}

/* Login */

export function login(mail, password) {
  const body = {
    cagrilanFonks: "Login",
    mail,
    password,
  }
  return makeRequest(body)
}

/* Raffle */

export function saveRaffle(
  creator,
  description,
  rating,
  type,
  participants,
  winners,
  reserveWinners
) {
  const body = {
    cagrilanFonks: "CekilisKaydet",
    creator,
    description,
    rating,
    type,
    participants: JSON.stringify(participants),
    winners: JSON.stringify(winners),
    reserveWinners: JSON.stringify(reserveWinners),
  }
  return makeRequest(body)
}

export function getRaffleResult(raffleId) {
  const body = {
    cagrilanFonks: "CekilisSonucGetir",
    raffleId,
  }
  return makeRequest(body)
}
