import React, { useEffect } from "react"

import PropTypes from "prop-types"

export default function AdComponent({
  client = "ca-pub-2601982869161419",
  slot = "5723539166",
  format = "horizontal",
  responsive = "true",
}) {
  useEffect(() => {
    const getAd = () => (window.adsbygoogle = window.adsbygoogle || []).push({})
    getAd()
  }, [])

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client={client}
      data-ad-slot={slot}
      data-ad-format={format}
      data-full-width-responsive={responsive}
    />
  )
}

AdComponent.propTypes = {
  client: PropTypes.string,
  slot: PropTypes.string,
  format: PropTypes.string,
  responsive: PropTypes.string,
}
