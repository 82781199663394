import React, { useEffect, useState } from "react"
import {
  faExclamationTriangle,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import ShareIcons from "components/share-button/share-icons"
import { getRaffleResult } from "utils/api"

const CekilisSonuc = ({ id }) => {
  const [notFound, setNotFound] = useState(false)
  const [result, setResult] = useState(null)

  useEffect(() => {
    getRaffleResult(id)
      .then(x => x.json())
      .then(json => {
        if (json === "404") {
          setNotFound(true)
          return
        }
        setResult(json)
      })
  }, [])
  return (
    <>
      <div className="my-5" style={{ minHeight: 420 }}>
        {notFound && (
          <div
            className="text-center"
            style={{ fontSize: 32, lineHeight: 1.5 }}
          >
            <FontAwesomeIcon
              className="text-danger"
              icon={faExclamationTriangle}
            />
            <h1 className="font-weight-bold text-dark">
              {id} Kodlu Çekiliş Sonucu Bulunmamaktadır!
            </h1>
          </div>
        )}
        {!notFound && result && (
          <div className="d-flex flex-column align-items-center">
            <h1 className="font-weight-bold text-dark mb-4">
              {id} Kodlu Çekiliş Sonucu
            </h1>
            <div className="text-muted">
              <b>{result.sahip}</b> tarafından <b>{result.tarih}</b> tarihinde
              yapıldı.
            </div>

            <div className="text-muted text-center">
              <div>
                Toplam <b>{result.katilimci.length}</b> katılımcı eklendi.
              </div>
              <div>
                Bu katılımcılar arasından{" "}
                <FontAwesomeIcon className="text-warning" icon={faTrophy} />{" "}
                <b>{result.kazanan.length}</b> kişi kazandı.{" "}
              </div>
              <div>
                {result.yedek.length == 0 && (
                  <FontAwesomeIcon className="text-primary" icon={faTrophy} />
                )}{" "}
                <b>{result.yedek.length == 0 && `${result.yedek.length}`}</b>{" "}
                {result.yedek.length == 0 && `kişi ise yedek olarak kazandı.`}
              </div>
            </div>
            <div className="my-2 text-monospace">{result.aciklama}</div>
            <div className="my-3">
              <ShareIcons shareId={id} />
            </div>

            <div className="mt-4">
              <h3 className="text-primary">Kazanan Listesi</h3>
              <ol lass="list-group">
                {result.kazanan.map((name, i) => (
                  <li key={i}>
                    <FontAwesomeIcon className="text-warning" icon={faTrophy} />{" "}
                    {name}
                  </li>
                ))}
              </ol>
            </div>

            {result.yedek > 0 && (
              <div className="mt-4">
                <h3 className="text-primary">Yedek Listesi</h3>
                <ol lass="list-group">
                  {result.yedek.map((name, i) => (
                    <li key={i}>
                      <FontAwesomeIcon
                        className="text-primary"
                        icon={faTrophy}
                      />{" "}
                      {name}
                    </li>
                  ))}
                </ol>
              </div>
            )}
            <div className="mt-4">
              <h3 className="text-primary text-center">Katılımcı Listesi</h3>
              <ul className="list-unstyled">
                {result.katilimci.map((name, i) => (
                  <li className="text-center" key={i}>
                    {name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

CekilisSonuc.propTypes = {
  id: PropTypes.string,
}

export default CekilisSonuc
