import { faCircle, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookF,
  faTelegramPlane,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import React from "react"
import useTranslations from "components/use-translations"

export default function ShareIcons({ shareId }) {
  const {
    cekilis: {
      cekilisSonucunuPaylas,
      cekilisSonucuBelliOldu,
      cekilisSonucuBelliOlduMail,
      cekilisSonucHashtag,
      adresindenBakabilirsin,
      cekilisSonucMailBody,
    },
  } = useTranslations()
  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <div className="font-weight-bold">{cekilisSonucunuPaylas}</div>
        <div className="d-flex flex-row mt-2" style={{ fontSize: 8 }}>
          <a
            href={`https://www.facebook.com/sharer.php?u=https://www.kodvizit.com/cekilis/s/${shareId}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <span className="fa-stack fa-4x">
              <FontAwesomeIcon
                color="#3c5b9b"
                className="fa-stack-2x"
                icon={faCircle}
              />
              <FontAwesomeIcon
                color="#fff"
                className="fa-stack-1x"
                icon={faFacebookF}
              />
            </span>
          </a>

          <a
            href={`https://twitter.com/share?url=https://www.kodvizit.com/cekilis/s/${shareId}&text=${cekilisSonucuBelliOldu}&hashtags=${cekilisSonucHashtag}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <span className="fa-stack fa-4x">
              <FontAwesomeIcon
                color="#2DAAE1"
                className="fa-stack-2x"
                icon={faCircle}
              />
              <FontAwesomeIcon
                color="#fff"
                className="fa-stack-1x"
                icon={faTwitter}
              />
            </span>
          </a>

          <a
            href={`whatsapp://send?text=https://www.kodvizit.com/cekilis/s/${shareId} ${adresindenBakabilirsin}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <span className="fa-stack fa-4x">
              <FontAwesomeIcon
                color="#80D280"
                className="fa-stack-2x"
                icon={faCircle}
              />
              <FontAwesomeIcon
                color="#fff"
                className="fa-stack-1x"
                icon={faWhatsapp}
              />
            </span>
          </a>

          <a
            href={`https://t.me/share/url?url=https://www.kodvizit.com/cekilis/s/${shareId}&text=${cekilisSonucuBelliOldu}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <span className="fa-stack fa-4x">
              <FontAwesomeIcon
                color="#54A9EA"
                className="fa-stack-2x"
                icon={faCircle}
              />
              <FontAwesomeIcon
                color="#fff"
                className="fa-stack-1x"
                icon={faTelegramPlane}
              />
            </span>
          </a>
          <a
            href={`mailto:?Subject=${cekilisSonucuBelliOlduMail}&Body=${cekilisSonucMailBody} https://www.kodvizit.com/cekilis/s/${shareId}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <span className="fa-stack fa-4x">
              <FontAwesomeIcon
                color="#5AB827"
                className="fa-stack-2x"
                icon={faCircle}
              />
              <FontAwesomeIcon
                color="#fff"
                className="fa-stack-1x"
                icon={faEnvelope}
              />
            </span>
          </a>
        </div>
      </div>
    </>
  )
}

ShareIcons.propTypes = {
  shareId: PropTypes.string,
}
