import AdComponent from "components/ad-component"
import Container from "react-bootstrap/Container"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Footer from "components/footer"
import Header from "./header"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import React from "react"
import { faVideo } from "@fortawesome/free-solid-svg-icons"
import useTranslations from "components/use-translations"
import { Col, Row } from "react-bootstrap"

export const RaffleTypes = Object.freeze({
  Hizli: 0,
  Afilli: 1,
  Cark: 2,
  RastgeleSayi: 3,
  Zar: 4,
  YaziTura: 5,
  Instagram: 6,
  TikTok: 7,
})
export default function LayoutCekilis({
  children,
  headerText,
  howToLink,
  className,
  type,
}) {
  const {
    cekilis: { nasilYapilirHemenIzle },
  } = useTranslations()
  return (
    <div>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
      </Helmet>
      <Header type={type}></Header>
      <Container>
        <AdComponent height={100} />
        <article className={`container ${className && className}`}>
          <h1 className="mt-3" style={{ fontSize: 26, fontWeight: 600 }}>
            {headerText}
          </h1>
          {howToLink && (
            <div className="small">
              <a href={howToLink}>
                <div
                  className="d-inline-block"
                  style={{ width: "16px", height: "16px" }}
                >
                  <FontAwesomeIcon icon={faVideo} className="mr-1" />
                </div>
                {nasilYapilirHemenIzle}
              </a>
            </div>
          )}
          {children}
        </article>

        <AdComponent slot="8148013215" height={100} />

        <div className="mt-2">
          <Row>
            <Col md="8">
              <h1 className="h4">
                Çekiliş yap sitesi ne işe yarıyor? Bu websitesi nasıl
                kullanılır?
              </h1>
              <p>
                Bu internet sitesi size en ideal çekiliş yapma ve bu çekilişi
                paylaşma hizmetini vermektedir. Her zaman, en hızlı ve en kolay
                şekilde çekiliş yapmanız için ücretsiz hizmet sağlıyoruz.
              </p>

              <h2 className="h4 mt-1">Birçok çekiliş yöntemini destekler</h2>
              <p>
                Çekiliş yap internet sitesi, birden fazla çekiliş yöntemini
                desteklemektedir. Bunun sayesinde istediğiniz türde çekilişler
                yapabilirsiniz. Bunun bir diğer faydası da katılımcılarınıza
                çekilişlerinizi görsel olarak sunabilmenizdir.
              </p>

              <h2 className="h4 mt-1">Tamamen ücretsizdir!</h2>
              <p>
                Çekiliş yapmak için çekiliş yap sitesine hiçbir ücret
                ödemezsiniz. Ayrıca çekilişlerinizi paylaşmadığınız sürece
                çekilişleriniz ve katılımcıların bilgileri sistemimizde
                tutulmaz. Böylece güvenli ve zahmetsiz bir şekilde çekiliş
                yapabilirsiniz.
              </p>

              <h2 className="h4 mt-1">Instagram yorumlarını ayrıştırabilir</h2>
              <p>
                Instagram sitesindeki çekilişlerinizi ayrıştırabilmeniz için
                çekiliş sitesinde bir araç bulunur. Bu araç sayesinde ücretsiz
                ve detaylı bir şekilde instagram üzerinde yaptığınız çekiliş
                paylaşımındaki yorumları ayrıştırıp, çekilişi
                gerçekleştirebilirsiniz.
              </p>

              <h2 className="h4 mt-1">Çekiliş nasıl yapılır</h2>
              <p>
                Her bir satıra bir katılımcı gelecek şekilde katılımcı
                isimlerinizi kopyalayın. Giriş alanına bu katılımcıları
                yapıştırın ve «Çekilişi Başlat» butonuna tıklayın. İşlem tamam!
              </p>
            </Col>
            <Col md="4">
              <AdComponent slot="7560903042" format="vertical, rectangle" />
            </Col>
          </Row>
        </div>
      </Container>

      <Footer />
    </div>
  )
}
LayoutCekilis.displayName = "LayoutCekilis"
LayoutCekilis.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  howToLink: PropTypes.string,
  type: PropTypes.number.isRequired,
}
